import VueMouseflow from '@/plugins/mouseflow'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2'
import dayjs from 'dayjs'
import IdleVue from 'idle-vue'
import IdleVueComponent from 'idle-vue/src/components/Idle.vue'
import VueMask from 'v-mask'
import Vue from 'vue'
import VueTour from 'vue-tour'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import App from './App.vue'
import './plugins/acl'
import StandardizedControlLibrary from './plugins/controllibrary'
import signalR from './plugins/signalr'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// Application Insights
const appInsightsConnectionString = process.env.NODE_ENV === 'production'
  ? 'InstrumentationKey=60768a1d-b528-49b9-a7ce-7b6043012802;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/;ApplicationId=6ced96ad-915b-45c0-ae5a-6f96d41f5eeb'
  : ''
const appInsights = new ApplicationInsights({
  config: {
    connectionString: appInsightsConnectionString,
  },
})
appInsights.loadAppInsights()
appInsights.trackPageView() // Manually call trackPageView to establish the current user/session/pageview

// Vue filter(s)
Vue.filter('formatDateString', value => (value ? dayjs(value).format('MM/DD/YYYY') : null))
Vue.filter('formatDateTimeString', value => (value ? dayjs(value).format('MM/DD/YYYY h:mm:ss a') : null))

// Convert UTC to local date and format as MM/DD/YYYY
Vue.filter('formatDateStringUtcToLocal', value => {
  if (!value) return null

  return dayjs(new Date(value)).format('MM/DD/YYYY')
})

// Convert UTC to local datetime and format as MM/DD/YYYY h:mm:ss a
Vue.filter('formatDateTimeStringUtcToLocal', value => {
  if (!value) return null

  return dayjs(new Date(value)).format('MM/DD/YYYY h:mm:ss a')
})
Vue.filter('formatDollar', value => (typeof value === 'number'
  ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
  : value))

// Vue Mask
Vue.use(VueMask)

// Idle view
Vue.component('idle-view', IdleVueComponent) // Required only to use idle-view component

const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 60000 * 1 * 60, // # (60000 * 1) = 1 minute
  startAtIdle: false,
})

// SignalR
// Vue.use(signalR)

// Mouseflow
Vue.use(VueMouseflow, { tracking_key: 'd150ee35-0cec-4c82-ab08-f306a7356413' })

// Tawk
// Vue.use(TawkMessengerVue, { propertyId: '626ac9dfb0d10b6f3e6fd2bc', widgetId: '1g1oi94jf' })

// Vue Tour
require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

// Standardized App Control Library
Vue.use(StandardizedControlLibrary)

// Vue directive(s)
Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted(el) {
    // Focus the element
    el.focus()
  },
})

// Vue
new Vue({
  router,
  store,
  vuetify,

  mounted() {
    window.addEventListener('popstate', this.handleBackButton)
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handleBackButton)
  },
  methods: {
    handleBackButton() {
      console.log('back button pressed')

      // Check if the application is within an iframe
      if (window.self !== window.top) {
        console.log('In iFrame')

        // Trigger the parent site's back button action
        window.top.history.back()
      } else {
        console.log('Not in iFrame')

        // Handle the back button action within the application itself
        // You can define the behavior for the back button when not within an iframe
        // For example: router.back() to navigate within the Vue application
        // router.back()
      }
    },
  },

  render: h => h(App),

  // onIdle() {
  //   console.log('User is inactive')
  // },
  // onActive() {
  //   console.log('User is active')
  // },
}).$mount('#app')
