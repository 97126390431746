<script>
import { watch, ref } from '@vue/composition-api'
import { mdiLogoutVariant, mdiPowerOff } from '@mdi/js'
import useUser2SessionContext from '@/features/user2/_framework/composables/useUser2SessionContext'

export default {
  name: 'SignOutControl',
  components: {},
  props: {
    asIcon: {
      type: Boolean,
      default: true,
    },
    sessionExpired: {
      type: Boolean,
      default: false,
    },
    removeOrganizationContext: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const {
      removeActiveOrganizationContext,
    } = useUser2SessionContext()

    // =========================================
    // form
    // =========================================
    const form = ref(null)

    const preSubmit = () => {
      if (props.removeOrganizationContext) {
        removeActiveOrganizationContext()
      }
      context.refs.form.$el.submit()
    }

    watch(
      () => props.sessionExpired,
      async (newValue, oldValue) => {
        if (newValue && form.value) {
          context.refs.form.$el.submit()
        }
      },
    )

    return {
      form,
      preSubmit,

      icons: {
        mdiLogoutVariant,
        mdiPowerOff,
      },
    }
  },
}
</script>

<template>
  <div id="sign-out-component">
    <v-form
      ref="form"
      method="post"
      action="/demo/OrganizedBits.OpenIdClient/Account/Logout"
    >
      <v-btn
        v-if="asIcon"
        ref="submitButton"
        small
        text
        plain
        type="submit"
        @click.prevent="preSubmit"
      >
        <v-icon>{{ icons.mdiLogoutVariant }}</v-icon>
        <span class="ml-2">Sign out</span>
      </v-btn>
      <v-btn
        v-else
        ref="submitButton"
        outlined
        color="secondary"
        class="me-3"
        type="submit"
        @click.prevent="preSubmit"
      >
        Sign out
      </v-btn>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
// div {
//   border: 1px solid black;
// }
</style>
