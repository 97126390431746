import dayjs from 'dayjs'
import swal from 'sweetalert2'

// eslint-disable-next-line import/prefer-default-export
export const base64StringToBlob = dataURI => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type: mimeString })
}

export const blobToBase64String = (file, callback, errorCallback) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => {
    callback(reader.result)
  }
  reader.onerror = error => {
    errorCallback(error)
  }
}

export const featurePending = () => {
  swal.fire({
    title: 'Still to come!',
    text: 'This feature is not yet implemented',
    icon: 'info',
    confirmButtonText: 'Got it',
    confirmButtonColor: '#3085d6',
  })
}

export const debounce = (fn, delay) => {
  let timeout

  return (...args) => {
    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

export const priceFormatter = value => {
  const val = (value / 1).toFixed(2)

  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const isDate = date => (new Date(date) !== 'Invalid Date') && !(new Date(date).isNaN)
export const formatDate = date => (date ? dayjs(date).format('MM/DD/YYYY') : null)

export const resolveImage = (url, height = 150) => {
  if (url && url.length > 0) {
    return `/demo/${url}?height=${height}`
  }

  return ''
}

export const getUserTimezone = () => {
  const date = new Date()
  const offsetInMinutes = date.getTimezoneOffset()
  const offsetInHours = Math.abs(offsetInMinutes / 60)
  const offsetInMinutesString = Math.abs(offsetInMinutes % 60).toString().padStart(2, '0')
  const offsetString = `${(offsetInMinutes > 0 ? '-' : '+') + offsetInHours.toString().padStart(2, '0')}:${offsetInMinutesString}`
  const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timeZone = { name: timeZoneName, offset: offsetString }

  return timeZone
}

export const getUserTimezoneName = () => Intl.DateTimeFormat().resolvedOptions().timeZone

export const formatAsLocalDateTime = (sendDate, sendTime) => {
  if (!sendDate && !sendTime) {
    return ''
  }

  // Create a Date object in UTC
  const date = sendDate ? new Date(`${sendDate}T00:00:00Z`) : new Date()

  const timeParts = sendTime ? sendTime.split(':') : null

  if (timeParts) {
    const hours = parseInt(timeParts[0], 10)
    const minutes = parseInt(timeParts[1], 10)

    // Set the time in UTC
    date.setUTCHours(hours, minutes, 0, 0)
  }

  // Format the date without converting to UTC
  const year = date.getUTCFullYear()
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0')
  const day = date.getUTCDate().toString().padStart(2, '0')
  const hours = date.getUTCHours().toString().padStart(2, '0')
  const minutes = date.getUTCMinutes().toString().padStart(2, '0')
  const seconds = date.getUTCSeconds().toString().padStart(2, '0')

  // Create the formatted datetime string without time zone offset
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
}

// Utility function to convert "HH:mm" to "HH:mm:ss"
export const formatTime = time => {
  if (time && time.length === 5) {
    return `${time}:00`
  }

  return time
}
