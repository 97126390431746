<script>
import { computed } from '@vue/composition-api'
import { mdiCloseCircle } from '@mdi/js'
import useApiError from '@/core/api/useApiError'
import useActivity from '@/features/activity/_framework/composables/useActivity'

export default {
  name: 'ApiErrorAlertDisplay',
  components: {},
  props: { },
  setup() {
  // =========================================
  // error handling
  // =========================================
    const {
      errorAlerts,
    } = useApiError()

    const {
      createErrorSubmission,
    } = useActivity()

    // =========================================
    // alerts
    // =========================================
    const displayErrors = computed(() => errorAlerts.value.filter(obj => obj.display === true).slice(0, 3))

    // const dismissAlert = instanceId => {
    //   const errorToUpdate = errorAlerts.value.find(error => error.instance === instanceId)
    //   if (errorToUpdate) {
    //     errorToUpdate.display = false
    //   }
    // }

    const dismissAlert = instanceId => {
      const index = errorAlerts.value.findIndex(error => error.instance === instanceId)
      if (index !== -1) {
        // errorAlerts.value[index].display = false
        errorAlerts.value.splice(index, 1)
      }
    }

    const dismissAllAlerts = () => {
      errorAlerts.value.splice(0)
    }

    // =========================================
    // report errors
    // =========================================
    const hasNonValidationErrors = computed(() => errorAlerts.value.filter(obj => !obj.isValidationError).length > 0)
    const reportErrors = computed(() => errorAlerts.value.filter(obj => !obj.isValidationError && obj.messageSent === false && obj.errorType === 'ClientError'))

    const sendErrorReport = async () => {
      // Send error report
      const apiData = {
        dto: errorAlerts.value.map(apiError => ({
          errorId: apiError.instance,
          errorSummary: apiError.title,
          errorDetail: apiError.detail,
        })),

      }
      if (await createErrorSubmission(apiData)) {
        errorAlerts.value = errorAlerts.value.map(errorAlert => ({
          ...errorAlert,
          messageSent: true,
        }))
      }
    }

    // =========================================
    // return
    // =========================================
    return {
      // alerts
      displayErrors,
      dismissAlert,
      dismissAllAlerts,

      // report errors
      hasNonValidationErrors,
      reportErrors,
      sendErrorReport,

      errorAlerts,

      // standard
      icons: {
        mdiCloseCircle,
      },
    }
  },
}
</script>

<template>
  <div id="api-error-alert-display">
    <v-row
      v-if="displayErrors.length > 0"
      dense
    >
      <v-col
        v-if="displayErrors.length > 0 && hasNonValidationErrors"
        cols="12"
        class="d-flex justify-end py-0"
      >
        <v-btn
          v-if="reportErrors.length > 0"
          color="error"
          plain
          small
          class="font-weight-semibold py-0"
          style="text-transform:none"
          @click="sendErrorReport"
        >
          Send error report
        </v-btn>
        <div v-else>
          <!-- <small class="text--secondary text-xs font-weight-semibold">The error report has been sent to AIC</small> -->
        </div>
      </v-col>
      <v-col
        v-if="displayErrors.length > 0"
        cols="12"
        class="d-flex justify-end py-0"
      >
        <v-btn
          color="error"
          plain
          small
          class="font-weight-semibold py-0"
          style="text-transform:none"
          @click="dismissAllAlerts"
        >
          Dismiss all errors
        </v-btn>
      </v-col>
    </v-row>

    <v-alert
      v-for="errorAlert in displayErrors"
      :key="errorAlert.instance"
      type="error"
      color="error"
      text
      prominent
      outlined
      dense

      elevation="0"
      transition="scale-transition"
    >
      <div class="alert-content">
        <v-row>
          <v-col cols="12">
            <div>{{ errorAlert.status }} {{ errorAlert.title }}</div>
            <!-- <small
              v-if="errorAlert.instance"
              class="text--secondary text-xs font-weight-semibold"
            >instance: {{ errorAlert.instance }}
            </small> -->
            <div>{{ errorAlert.detail }}</div>
            <!-- <div v-if="errorAlert.errors && Object.keys(errorAlert.errors).length > 0">
              <div
                v-for="(errorMessages, field) in errorAlert.errors"
                :key="field"
                class="error-field"
              >
                <div class="field-name">
                  {{ field }}:
                </div>
                <div
                  v-for="(errorMessage, index) in errorMessages"
                  :key="index"
                  class="error-item"
                >
                  {{ errorMessage }}
                </div>
              </div>
            </div> -->
          </v-col>
        </v-row>
      </div>

      <template v-slot:append>
        <v-btn
          icon
          small
          @click.stop="dismissAlert(errorAlert.instance)"
        >
          <v-icon color="error">
            {{ icons.mdiCloseCircle }}
          </v-icon>
        </v-btn>
      </template>
    </v-alert>

    <!-- <pre>displayErrors: {{ displayErrors }}</pre> -->
    <!-- <pre>reportErrors: {{ reportErrors }}</pre> -->
  </div>
</template>

<style lang="scss" scoped>
.alert-content {
  max-height: 100px; /* Set your desired max height */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
}
</style>
